import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { black } from "tailwindcss/colors";

gsap.registerPlugin(ScrollTrigger);

export default function Animations() {
  gsap.registerPlugin(ScrollTrigger);

  function setScrollImages() {
    gsap.to(".image-gallery", {
      scrollTrigger: {
        trigger: ".image-gallery-wall",
        pin: ".image-gallery-wall",
        toggleActions: "play reverse play reverse",
        scrub: true,
        // end: "+=3000s",
      },
      opacity: 1,
      background: black,
      scale: 0.4,
    });
  }
  setScrollImages();
}

import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Article(el) {
  const sections = [...el.querySelectorAll("[data-article] > article")];
  const nav = el.querySelector("[data-article-nav]");

  sections.forEach((section) => {
    const link = document.createElement("a");
    link.innerText = section.dataset.title;
    link.href = "#/";
    nav.appendChild(link);

    ScrollTrigger.create({
      trigger: section,
      //   trigger viewport
      start: "top center",
      end: "bottom center",
      // markers: true,
      onEnter: () => {
        setActive(section);
        console.log("enter");
      },
      onEnterBack: () => {
        setActive(section);
      },
    });
  });

  function setActive(section) {
    const index = sections.indexOf(section);
    console.log(nav.children);
    [...nav.children].forEach((c) => c.classList.remove("active"));
    nav.children[index].classList.add("active");
  }

  nav.querySelectorAll("a").forEach((link) =>
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const index = [...nav.children].indexOf(e.target);
      sections[index].scrollIntoView({
        behavior: "smooth",
      });
    })
  );
}

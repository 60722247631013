import "core-js/stable";
import "regenerator-runtime/runtime";
import barba from "@barba/core";
import Animations from "./js/Animations";
import Article from "./js/Article";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import Headroom from "headroom.js";
import "./app.scss";
import lazySizes from "lazysizes";

let actions = {};
function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

barba.hooks.beforeEnter((data) => {});
barba.hooks.after((data) => {
  runActions();
});

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);

runActions();

function runActions() {
  Animations();
  action("Article", Article);
  const headroomElement = document.querySelector(".top-navigation");
  const headroom = new Headroom(headroomElement, {
    offset: 200,
  });
  headroom.init();
}
